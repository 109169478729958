import get from 'lodash/get'

/**
 * Calculate deduct price for booking detail page (OT/MP)
 * @param {object} bookingDetail 
 * @returns {number} Base package price + additional price
 */
export const calculateDeductPrice = (bookingDetail = {}) => {
  // Equipment Price
  const baseTotalPrice = get(bookingDetail, 'price', 0)
  const additionalPrice = get(bookingDetail, 'multi_times', 1) * get(bookingDetail, 'equipment_price', 0)
  return baseTotalPrice + additionalPrice
}